

(function () {
	return {
		// Properties
		carousels: document.querySelectorAll('carousel'),
		xDown: null,
		yDown: null,


		handleTouchStart(evt) {
			this.xDown = evt.changedTouches[0].clientX;
			this.yDown = evt.changedTouches[0].clientY;
		},

		handleTouchMove(evt) {
			if (!this.xDown || !this.yDown) {
				return;
			}

			const xUp = evt.changedTouches[0].clientX,
						yUp = evt.changedTouches[0].clientY;

			const xDiff = this.xDown - xUp,
						yDiff = this.yDown - yUp;

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				if (xDiff > 0) {
					// Left
					this.querySelector("[data-action=\"next\"]").click();
				} else {
					// Right
					this.querySelector("[data-action=\"prev\"]").click();
				}
			} /*else {
				if (yDiff > 0) {
					// up
				} else {
					// down
				}
			}*/

			// Reset
			this.xDown = null;
			this.yDown = null;
		},

		// Methods
		init() {
			// Set initial properties
			const _this = this,
				carousels = this.carousels;

			// Loop through carousel content
			let i = 0;
			const length = carousels.length;

			for (; i < length; ++i) {
				(function (i) {
					const carousel = carousels[i],
								childrenContainer = carousel.querySelector('children > .carousel__inner'),
								children = carousel.querySelectorAll('child');


					// Set events on the carousel
					carousel.addEventListener('touchstart', this.handleTouchStart, false);
					carousel.addEventListener('touchmove', this.handleTouchMove, false);

					document.addEventListener('imageCarouselReady', () => {
						// Fetch child
						const activeChild = children[activeImageIndex];

						// Dispatch event
						document.dispatchEvent(new CustomEvent('carouselinvoked', {
							detail: {
								direction: 'next',
								type: activeChild.querySelector('.child-inner').firstChild.tagName,
								parent: activeChild.closest('image-carousel'),
								activeElement: activeChild,
								hazcheeseburger: true
							}
						}));
					});

					let activeImageIndex = 0,
						shownElements = parseInt(carousel.getAttribute('data-shownelements'), 10);

					// Test for even
					if (shownElements % 2 === 0) {
						++shownElements;
					}

					// Initially set active image index (element in the visibile center of the carousel)
					activeImageIndex = Math.floor(shownElements / 2);

					// Set strip items
					_this.setStripItems(childrenContainer, children, activeImageIndex, shownElements);

					// Navigation handling
					const nextButton = carousel.querySelector('navigation > button[data-action="next"]'),
								prevButton = carousel.querySelector('navigation > button[data-action="prev"]');


					// Click handlers for buttons
					nextButton.addEventListener('click', () => {
						// Animate first
						childrenContainer.classList.add('carousel__inner--moveright');

						// Set strip items and new active image index
						activeImageIndex = _this.incrementImageIndex(activeImageIndex, children);

						// Fetch child
						const activeChild = children[activeImageIndex];

						// Dispatch event
						document.dispatchEvent(new CustomEvent('carouselinvoked', {
							detail: {
								direction: 'next',
								type: activeChild.querySelector('.child-inner').firstChild.tagName,
								parent: activeChild.closest('image-carousel'),
								activeElement: activeChild,
								hazcheeseburger: true
							}
						}));

						setTimeout(function () {
							// Set strip items
							_this.setStripItems(childrenContainer, children, activeImageIndex, shownElements);
						}, 300);
					});

					prevButton.addEventListener('click', () => {
						// Animate first
						childrenContainer.classList.add('carousel__inner--moveleft');

						// Set strip items and new active image index
						activeImageIndex = _this.decrementImageIndex(activeImageIndex, children);

						// Fetch child
						const activeChild = children[activeImageIndex];

						// Dispatch event
						document.dispatchEvent(new CustomEvent('carouselinvoked', {
							detail: {
								direction: 'previous',
								type: activeChild.querySelector('.child-inner').firstChild.tagName,
								parent: activeChild.closest('image-carousel'),
								activeElement: activeChild,
								hazcheeseburger: true
							}
						}));

						setTimeout(function () {
							// Set strip items
							_this.setStripItems(childrenContainer, children, activeImageIndex, shownElements);
						}, 300);
					});
				}.bind(this))(i);
			}
		},

		setStripItems(childrenContainer, children, activeImageIndex, shownElements) {
			// Remove classes
			childrenContainer.parentNode.classList.remove('carousel__children--showchildren');
			childrenContainer.classList.remove('carousel__inner--moveleft');
			childrenContainer.classList.remove('carousel__inner--moveright');

			// Create children set
			let childrenSet = [];

			{
				let i = 0;
				const cLength = children.length;

				for (; i < cLength; i++) {
					childrenSet.push(children[i].cloneNode(true));

					if (children[i].parentNode !== null) {
						children[i].parentNode.removeChild(children[i]);
					}
				}
			}

			// Create array to contain the children to insert
			let childrenToInsert = [];
			const offsetElementsAmounts = (Math.floor(shownElements / 2));

			// Fill with missing elements at beginning
			{
				let i = (offsetElementsAmounts * -1) - 1;
				const length = 0;

				for (; i < length; i++) {
					const childIndex = (activeImageIndex + i);

					if (childIndex < 0) {
						childrenToInsert.push(children[children.length + childIndex]);
					}
					else {
						childrenToInsert.push(children[childIndex]);
					}
				}
			}

			// Set main items in strip
			childrenToInsert.push(children[activeImageIndex]);

			// Fill with missing elements at end
			{
				let i = activeImageIndex;
				const length = activeImageIndex + offsetElementsAmounts + 1;

				for (; i < length; i++) {
					const childIndex = i + 1;

					if (childIndex >= children.length) {
						childrenToInsert.push(children[childIndex - children.length]);
					}
					else {
						childrenToInsert.push(children[childIndex]);
					}
				}
			}

			// Insert children as nodes
			{
				let i = 0;
				let length = childrenToInsert.length;

				for (; i < length; i++) {
					(function (i) {
						childrenContainer.appendChild(childrenToInsert[i]);
					})(i);
				}
			}

			// Add class
			childrenContainer.parentNode.classList.add('carousel__children--showchildren');
		},



		//
		//
		// Helper methods
		//

		incrementImageIndex(activeImageIndex, children) {
			if ((activeImageIndex + 1) === children.length) {
				return 0;
			}
			else {
				return activeImageIndex + 1;
			}
		},

		decrementImageIndex(activeImageIndex, children) {
			if ((activeImageIndex - 1) < 0) {
				return children.length - 1;
			}
			else {
				return activeImageIndex - 1;
			}
		}
	};
})().init();
