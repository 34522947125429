(function () {
	return {
		init: function () {
			var buttons = document.querySelectorAll('.js-expand-button');

			(function () {
				var i = 0,
						length = buttons.length;
				
				for (; i < length; i++) {
					var container = buttons[i].nextSibling,
							content = container.firstChild;

					buttons[i].addEventListener('click', function (event) {
						event.target.className += ' hidden';

						// Disable button for good measure (screen readers and such)
						event.target.setAttribute('disabled', 'disabled');

						// Update ARIA attribute
						event.target.setAttribute('aria-expanded', true);

						container.style.height = content.getBoundingClientRect().height;

						setTimeout(function () {
							container.className += ' is-active';
							container.removeAttribute('style');
						}, 700);
					});
				}
			})();
		}
	}
})().init();