//import particlesJS from "../../scripts/lib/particles.min";
window.particlesJS('particles-js', {
	'particles': {
		'number': {
			'value': 250,
			'density': {
				'enable': true,
				'value_area': 800
			}
		},
		'color': {
			'value': '#ffffff'
		},
		'shape': {
			'type': 'circle',
			'stroke': {
				'width': 0,
				'color': '#000000'
			},
			'polygon': {
				'nb_sides': 5
			},
			'image': {
				'src': 'img/github.svg',
				'width': 100,
				'height': 100
			}
		},
		'opacity': {
			'value': 0.9,
			'random': true,
			'anim': {
				'enable': true,
				'speed': 1,
				'opacity_min': 0,
				'sync': false
			}
		},
		'size': {
			'value': 3,
			'random': true,
			'anim': {
				'enable': true,
				'speed': 1,
				'size_min': 0.1,
				'sync': false
			}
		},
		'line_linked': {
			'enable': false,
			'distance': 150,
			'color': '#ffffff',
			'opacity': 0.4,
			'width': 1
		},
		'move': {
			'enable': true,
			'speed': 1.3,
			'direction': 'none',
			'random': true,
			'straight': false,
			'out_mode': 'out',
			'bounce': false,
			'attract': {
				'enable': false,
				'rotateX': 600,
				'rotateY': 1200
			}
		}
	},
	'interactivity': {
		'detect_on': 'canvas',
		'events': {
			'onhover': {
				'enable': true,
				'mode': 'bubble'
			},
			'onclick': {
				'enable': false,
				'mode': 'push'
			},
			'resize': true
		},
		'modes': {
			'grab': {
				'distance': 140,
				'line_linked': {
					'opacity': 1
				}
			},
			'bubble': {
				'distance': 120,
				'size': 0,
				'duration': 3,
				'opacity': 0,
				'speed': 2
			},
			'repulse': {
				'distance': 200,
				'duration': 0.4
			},
			'push': {
				'particles_nb': 4
			},
			'remove': {
				'particles_nb': 2
			}
		}
	},
	'retina_detect': true
});
