
(function () {
	return {
		// Methods
		init() {
			
			// Listen for changes
			document.addEventListener("carouselinvoked", function(e) {
				const parent = e.detail.parent;

				if (parent !== null) {
					const container = parent.querySelector("container"),
								activeElement = e.detail.activeElement,
								type = e.detail.type;
							
					if (type.toString().toLowerCase() === "thumb-image") {
						const imageUrl = activeElement.querySelector("thumb-image").getAttribute("data-url");
						let image = new Image();
						
						image.addEventListener("load", function() {
							container.innerHTML = "";
							container.appendChild(this);
						});

						image.src = imageUrl;
					}
					else if (type.toString().toLowerCase() === "thumb-video") {
						const videoId = activeElement.querySelector("thumb-video").getAttribute("data-id");

						let videoContainer = document.createElement("div");
						videoContainer.classList.add("image-carousel__video-container");

						let video = document.createElement("iframe");
						video.src = "https://www.youtube.com/embed/" + videoId + "?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=0";
						video.frameBorder = "0";
						video.allowFullscreen = "allowfullscreen";

						// Add to video container
						videoContainer.appendChild(video);

						// Add to main container
						container.innerHTML = "";
						container.appendChild(videoContainer);
					}
				}
			});

			// Dispatch event about being active
			document.dispatchEvent(new CustomEvent("imageCarouselReady"));
		}
	}
})().init();
