class Cta {
	constructor() {
		this.properties = {
			ticking: false,
			scroll: window.requestAnimationFrame ||
					window.webkitRequestAnimationFrame ||
					window.mozRequestAnimationFrame ||
					window.msRequestAnimationFrame ||
					window.oRequestAnimationFrame
		};

		this.elements = {
			ctas: document.querySelectorAll('.js-cta')
		};

		this.checkPosition();

		window.addEventListener('scroll', this.debounce.bind(this), false);

		for (var i = 0; i < this.elements.ctas.length ; i++) {
			this.elements.ctas[i].addEventListener('click',
				function (event) {
					event.preventDefault();
					var target = event.currentTarget || event.target;
					var href = target.href;

					// tracking
					ga('send', 'event', 'outbound', 'click', href, {
						'transport': 'beacon',
						'hitCallback': function(){ document.location = href; }
					});

					window.adf&&adf.ClickTrack(this,906975,'Køb billet',{});
				},
			false);
		}
	}

	debounce() {
		if (!this.properties.ticking) {
			this.properties.scroll.call(window, this.checkPosition.bind(this));
		}
		this.properties.ticking = true;
	}

	checkPosition () {
		(() => {
			var i = 0,
				length = this.elements.ctas.length;

			for (; i < length; i++) {
				var parent = this.elements.ctas[i].parentNode,
					container = parent.parentNode;

				if (container.getBoundingClientRect().bottom - this.elements.ctas[i].getBoundingClientRect().height <= 0) {
					if (this.elements.ctas[i].style.position !== 'fixed') {
						this.elements.ctas[i].style.position = 'fixed';
					}
				} else {
					if (this.elements.ctas[i].style.position !== '') {
						this.elements.ctas[i].style.position = '';
					}
				}
			}
		})();

		this.properties.ticking = false;
	}
}

new Cta();
